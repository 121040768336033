/* font converted using font-converter.net. thank you! */
@import './variables.scss';
@import './breakpoints';
@import './bulma';
@import './utilities';
@import './file';

html,
body,
#root {
  height: 100%;
  max-width: 100%;
  font-family: 'Gotham-Medium', sans-serif;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
.hover {
  &:hover {
    cursor: pointer;
  }
}

svg {
  cursor: pointer;
}

.my-auto {
  margin: auto 0;
}

.mx-auto {
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

input,
textarea {
  font-family: 'Gotham-Book', sans-serif;
}

.loading-container {
  width: 100%;
  text-align: center;
  padding: 10%;
}

.icon-disabled {
  svg {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
.color-orange {
  color: $orange;
}

.color-blue {
  color: $blue;
}

.color-gray {
  color: #b2bdcc;
}

.color-light-blue {
  color: $turqueza;
}

@mixin item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-white {
  color: #ffff !important;
}

.size-small {
  font-size: 10px !important;
}

.align-center {
  text-align: center !important;
}
