@font-face {
  font-family: 'Gotham-Black';
  src: url('./fonts/Gotham-Black.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/Gotham-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/Gotham-Black.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/Gotham-Black.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/Gotham-Black.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/Gotham-Black.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/Gotham-Black.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Bold.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/Gotham-Bold.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/Gotham-Bold.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/Gotham-Bold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/Gotham-Bold.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/Gotham-Bold.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */

@font-face {
  font-family: 'Gotham-BoldItalic';
  src: url('./fonts/GothamBoldItalic.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/GothamBoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/GothamBoldItalic.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/GothamBoldItalic.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/GothamBoldItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/GothamBoldItalic.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/GothamBoldItalic.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */

@font-face {
  font-family: 'Gotham-Book';
  src: url('./fonts/GothamBook.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/GothamBook.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/GothamBook.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/GothamBook.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/GothamBook.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/GothamBook.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/GothamBook.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */

@font-face {
  font-family: 'Gotham-BookItalic';
  src: url('./fonts/Gotham-BookItalic.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/Gotham-BookItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/Gotham-BookItalic.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/Gotham-BookItalic.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/Gotham-BookItalic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/Gotham-BookItalic.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/Gotham-BookItalic.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */

/* font converted using font-converter.net. thank you! */

@font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/GothamLight.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/GothamLight.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/GothamLight.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/GothamLight.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/GothamLight.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/GothamLight.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/GothamLight.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

/* font converted using font-converter.net. thank you! */

@font-face {
  font-family: 'Gotham-Medium';
  src: url('./fonts/GothamMedium1.eot');
  /* IE9 Compat Modes */
  src: url('./fonts/GothamMedium1.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/GothamMedium1.otf') format('opentype'),
    /* Open Type Font */ url('./fonts/GothamMedium1.svg') format('svg'),
    /* Legacy iOS */ url('./fonts/GothamMedium1.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/GothamMedium1.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/GothamMedium1.woff2') format('woff2');
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BlissPro-Light';
  src: url('./fonts/BlissPro-Light.otf') format('truetype');
}

@font-face {
  font-family: 'BlissPro-Heavy';
  src: url('./fonts/BlissPro-Heavy.otf') format('truetype');
}

.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}

.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}

.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}
.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.ma {
  margin: auto;
}
.ma-0 {
  margin: auto 0;
}

.bold {
  font-weight: bold;
}

.lighter {
  font-family: 'Gotham-Book', sans-serif;
  font-weight: 400;
}

.extra-bold {
  font-family: 'Gotham-Bold', sans-serif;
}

.m-1 {
  margin: 1rem !important;
}

.m-y {
  margin: 1rem 0 !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-0 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-0 {
  margin-top: 0.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-4 {
  margin-top: 4rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.d-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-all-center {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.align-center-end {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-flex-center {
  display: flex;
  justify-content: center;
}

.d-flex-center-align {
  display: flex;
  align-items: center;
}

.d-flex-separate {
  display: flex;
  justify-content: space-between;
}
.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.hidden-content-force {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.ma-1 {
  margin: auto 1rem;
}

.react-responsive-modal-modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding-top: 0;
  border-radius: 15px;
  .react-responsive-modal-closeButton {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.f-bold {
  font-family: 'Gotham-Bold', sans-serif;
}

.font-z-30 {
  font-size: 30px !important;
}
.font-z-20 {
  font-size: 20px !important;
}
.font-z-14 {
  font-size: 14px !important;
}
