@import "./variables.scss";

.download-file-boleta{
  font-family: 'initial', sans-serif !important;;
  margin: 0 1rem;
  width: $max-width-print-pdf;
  .report-filters{
    .filter-row{
      font-size: 11px!important;
      h6{
        min-width: 300px !important;
      }
    }
  }
}