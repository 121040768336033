//colors
$orange: #de6d19;
$orange-selected: #de6d1938;
$blue: #002655;
$turqueza: #35b1b8;
$gray-light-color: #f8f8f8;

//colores report
$blue-report: #342761;
$grey-report: #eae9ee;
$orange-report: #ff5100;
$bluish: #2663eb;
$dark-blue: #0f1241;

$max-width-print-pdf: 560px !important;

//spacing
$spacing-base: 12.5px;

//fonts

$font-family: 'Gotham-Book', sans-serif;
$font-family-medium: 'Gotham-Medium', sans-serif;
$font-family-lighter: 'Gotham-Book', sans-serif;
$font-family-bold: 'Gotham-Bold', sans-serif;

//fonts net Privada
$font-net-bold: 'BlissPro-Heavy', sans-serif;
$font-net-light: 'BlissPro-Light', sans-serif;

//paddings
$padding-modal: 10% 0 3%;

//efects
$box-shadow-card: 3px 3px 10px #004eaf12;
